// SurfInfo.jsx
import React, { useState, useEffect, useMemo } from 'react';
import SurfInfoTable from './SurfInfoTable';
import SurfOverview from './SurfOverview';
import tzlookup from 'tz-lookup';
import { isNightTime, isDuskTime, isDawnTime, getSunriseTime, getSunsetTime } from '../utils/timeUtils';

const SurfInfo = ({ latitude, longitude, onDataLoaded, hoverIndex, onCellClick, initialData, initialTideData, isServer, windData }) => {
  const [data, setData] = useState(null);
  const [tideData, setTideData] = useState(null);
  const [surfInfoData, setSurfInfoData] = useState(null);
  const [error] = useState(null);
  const [showAllTooltips, setShowAllTooltips] = useState(false);
  const [localTime, setLocalTime] = useState(null);
  const [closestTimeIndex, setClosestTimeIndex] = useState(null);

  const groupDates = (labels) => {
    return labels.reduce((acc, label, index) => {
      const [date, time] = label.split(' ');
      if (!acc[date]) {
        acc[date] = { startIndex: index, endIndex: index, times: [] };
      }
      acc[date].endIndex = index;
      acc[date].times.push(time);
      return acc;
    }, {});
  };

  const getLocalTime = (lat, long) => {
    const timezone = tzlookup(lat, long);
    return new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(new Date());
  };

  const isLastHourOfDay = (dateString) => {
    const date = new Date(dateString);
    return date.getHours() === 23;
  };

  const calculateWaveEnergy = (height, period) => {
    const periodSeconds = period * 3600;
    const energy = (0.5 * Math.pow(height, 2) * 9.81 * periodSeconds) / 1000;
    return Math.round(energy);
  };
  

  const findClosestTimeIndex = useMemo(() => {
    if (!data || !localTime) return null;
    const currentTime = new Date(localTime);
    let closestIndex = 0;
    let smallestDifference = Infinity;

    data.hourly.time.forEach((time, index) => {
      const difference = Math.abs(currentTime - new Date(time));
      if (difference < smallestDifference) {
        smallestDifference = difference;
        closestIndex = index;
      }
    });

    return closestIndex;
  }, [data, localTime]);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
      
      if (initialTideData) {
        const transformedTideData = initialTideData.tides.map((height) => ({
          height: height / 100,
        }));
        setTideData(transformedTideData);
      }
  
      const newSurfInfoData = initialData.hourly.time.map((time, index) => {
        const date = new Date(time + 'Z');
       // +Z adjustment for suntimes (utc0)
    
        return {
          date: date,
          energy: calculateWaveEnergy(initialData.hourly.wave_height[index], initialData.hourly.wave_period[index]),
          wave_direction: initialData.hourly.wave_direction[index],
          isNight: initialData.sunTimes ? isNightTime(date, initialData.sunTimes) : false,
          isDusk: initialData.sunTimes ? isDuskTime(date, initialData.sunTimes) : false,
          isDawn: initialData.sunTimes ? isDawnTime(date, initialData.sunTimes) : false,
          sunrise: initialData.sunTimes ? getSunriseTime(initialData.sunTimes, date) : null,
          sunset: initialData.sunTimes ? getSunsetTime(initialData.sunTimes, date) : null
        };
      });
      setSurfInfoData(newSurfInfoData);
  
      if (onDataLoaded) {
        onDataLoaded(newSurfInfoData);
      }
  
      // Calculate local time
      const currentLocalTime = getLocalTime(latitude, longitude);
      setLocalTime(currentLocalTime);
    }
  }, [initialData, initialTideData, latitude, longitude]);

  useEffect(() => {
    if (findClosestTimeIndex !== null) {
      setClosestTimeIndex(findClosestTimeIndex);
    }
  }, [findClosestTimeIndex]);

  if (error) return <div className="text-red-500">Error: {error}</div>;
  if (!data || !surfInfoData) return <div className="text-zinc-500">Loading...</div>;

  const labels = data.hourly.time.map((time) => {
    const date = new Date(time);
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    return `${dayOfWeek}\u00A0\u00A0${month}\u00A0${day} ${hours}`;
  });

  const waveEnergies = data.hourly.wave_height.map((height, index) =>
    calculateWaveEnergy(height, data.hourly.wave_period[index])
  );


  return (
    <>
      <div>
        <SurfOverview
          surfInfoData={surfInfoData}
          onHoverIndex={onCellClick}
          hoverIndex={hoverIndex}
          isServer={isServer}
          closestTimeIndex={closestTimeIndex}
          windData={windData}
          sunTimes={data.sunTimes}
        />
      </div>
      <SurfInfoTable
        data={data}
        surfInfoData={surfInfoData}
        tideData={tideData}
        labels={labels}
        waveEnergies={waveEnergies}
        groupDates={groupDates}
        isLastHourOfDay={isLastHourOfDay}
        calculateWaveEnergy={calculateWaveEnergy}
        hoverIndex={hoverIndex}
        onCellClick={onCellClick}
        showAllTooltips={showAllTooltips}
        setShowAllTooltips={setShowAllTooltips}
        closestTimeIndex={closestTimeIndex}
        windData={windData}
        sunTimes={data.sunTimes}
      />
    </>
  );
};

export default SurfInfo;